<template>
  <div class="myAccount">
    <Nav />

    <div class="bg-gray-50">
      <div class="container px-5 py-24 mx-auto flex justify-between items-stretch">
        <div class="flex flex-row">
          <div>
            <img alt="team" class="w-20 h-20 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/84x84">
          </div>
          <div class="self-center">
            <h1 class="font-bold sm:text-3xl text-2xl">Nickname</h1>
            <div>
              <span class="text-gray-400">Copy address · </span>
              <span class="text-gray-400">Report user</span>
            </div>
          </div>
        </div>
        <div class="self-center">
            <label v-if=1 for="modal-settings" class="inline-flex items-center focus:outline-none text-base cursor-pointer">
              <span class="text-black hover:text-gray-500 font-semibold">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"/>
                </svg>
              </span>
            </label>
          </div>
      </div>
    </div>

    <div>
      <section class="text-gray-600 body-font pt-4">
        <div class="container px-5 pb-24 mx-auto">
          <h1 class="text-xl font-bold title-font mb-2 text-gray-900">Your collection</h1>
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden">
                <router-link to="/dragonInfo">
                  <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/420x260">
                </router-link>
              </a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">FIRE</h3>
                <h2 class="text-gray-900 title-font text-lg font-medium">
                  <router-link to="/dragonInfo">The Catalyzer</router-link>
                </h2>
                <p class="mt-1">#1337</p>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden">
                <router-link to="/dragonInfo">
                  <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/420x260">
                </router-link>
              </a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">WATER</h3>
                <h2 class="text-gray-900 title-font text-lg font-medium">
                  <router-link to="/dragonInfo">The Catalyzer</router-link>
                </h2>
                <p class="mt-1">#228</p>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden">
                <router-link to="/dragonInfo">
                  <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/420x260">
                </router-link>
              </a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">WATER</h3>
                <h2 class="text-gray-900 title-font text-lg font-medium">
                  <router-link to="/dragonInfo">The Catalyzer</router-link>
                </h2>
                <p class="mt-1">#1995</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    <!--modal-->
    <input type="checkbox" id="modal-settings" class="modal-toggle"> 
    <div class="modal">
      <div class="modal-box">
        <h1 class="text-gray-900 font-medium title-font font-black py-2">Settings</h1>
        <div>
          <label class="flex items-center space-x-3 mb-3">
              <input type="checkbox" name="checked-demo" class="bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"/>
              <span class="text-gray-700 dark:text-white font-normal">
                  Daun
              </span>
          </label>
          <!--add settings-->
        </div>
        <div class="modal-action">
          <label for="modal-settings" class="text-white font-semibold bg-pink-500 border-0 py-1 px-8 focus:outline-none hover:bg-pink-600 rounded text-lg">Accept</label>
          <label for="modal-settings" class="text-white font-semibold bg-gray-500 border-0 py-1 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg">Close</label>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Nav from "../components/Nav";
  import Footer from "../components/Footer";

  export default {
    components: {Nav, Footer}
  }

</script>
